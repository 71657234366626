import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Card from '../components/Card';

const ProjectsPage = () => {
  return (
    <Layout>
      <h3>Projects</h3>
      <ProjectsContainer>
        <Card
          title='AimyOne - Aimy Limited, Auckland'
          desc='An SaaS management system for Class/Tuition Programmme. It has features such as Programme setup, Scheduler, Booking, Attendance, Invoice, Payment, Staff, Rostering, Communication and Notification systems. It is live on production having multiple providers, generating thousands of bookings and invoices.'
          tech='React, Redux, Redux Saga, umiJS, AntDesign UI, .Net Core, SQL Server, Azure Cloud'
          demo=''
          code=''
        />
        <Card
          title='Project Feline - MVP Studio, Auckland'
          desc='A centre-based ECE app that facilitates parents to enrol their child with a flexible booking time, and a special price code for a certain booking combination. It also supports 20 hours ECE subsidy for a child older than 3 years.'
          tech='React, Redux, Redux Saga, Syncfusion, umiJS, AntDesign UI, .NET Core, SQL Server'
          demo=''
          code=''
        />
        <Card
          title='Project Talent - MVP Studio, Auckland'
          desc="A cloud based recruitment platform that connects job seekers and employers. It helps job seekers find their dream job, as well as for employers find matching talents or browse job seekers' profile."
          tech='React, Redux, Redux Saga, umiJS, AntDesign UI, .NET Core, MongoDB, Webpack, Docker, AWS'
          demo=''
          code=''
        />
        <Card
          personal
          title='DK Bakery - Online Store'
          desc='A bakery online store. It has a set of menu with categories and prices,
          a real-time update of items quantity and total price in cart, also add to cart and check out features.'
          tech='React, Redux, Redux Saga, React Hooks, Firebase'
          demo='https://dk-bakery.herokuapp.com/'
          code='https://github.com/david-kur/dkbakery'
        ></Card>
        <Card
          personal
          title='TicTacToe - reactjs.org Tutorial'
          desc='TicTacToe from the tutorial of reactjs website, implemented with hooks and completed six challenges at the end of the tutorial.'
          tech='React, React Hooks'
          demo='https://dk-tictactoe.herokuapp.com/'
          code='https://github.com/david-kur/tictactoe'
        ></Card>
      </ProjectsContainer>
    </Layout>
  );
};

const ProjectsContainer = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-column-gap: 20px;
    grid-gap: 20px;
    max-width: 1000px;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export default ProjectsPage;
