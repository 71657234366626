import React from 'react';

import {
  CardContainer,
  CardTitle,
  CardTechContainer,
  CardTechText,
  CardDescription,
  CardLinksContainer
} from './styledCard';

import { StyledAnchor } from './styledLinkAnchor';

const Card = ({ title, tech, desc, demo, code, personal }) => {
  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{desc}</CardDescription>
      <CardTechContainer>
        <CardTechText>Tech: {tech}</CardTechText>
      </CardTechContainer>
      {personal && (
        <CardLinksContainer>
          <StyledAnchor href={demo} target="_blank">
            Demo
          </StyledAnchor>
          <StyledAnchor href={code} target="_blank">
            Code
          </StyledAnchor>
        </CardLinksContainer>
      )}
    </CardContainer>
  );
};

export default Card;
